@import '../components/menu-profile-landing';

.page-404-wrap {
  width: 100%;
  overflow: hidden;
}
.page-404-text {
  width: 545px;
  float: right;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: #ffc600;
  padding-top: 140px;
  margin-bottom: 55px;

  @media(max-width: 1024px) {
    width: 100%;
    text-align: center;
  }
  @media(max-width: 420px) {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 40px;
  }
}
.page-404-text span {
  display: block;
  color: #d32ce6;
}
.page-404-code {
  float: right;
  width: 545px;
  overflow: hidden;
  clear: both;

  @media(max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
  }
  @media(max-width: 560px) {
    flex-direction: column;
  }
}
.page-404-code .refer-input {
  width: 262px;
  height: 64px;
  display: block;
  float: left;
  position: relative;
}
.page-404-code .refer-input input {
  width: 100%;
  height: 46px;
  background-color: rgba(211, 44, 230, 0.2);
  background-image: linear-gradient(
    to top,
    transparent 0,
    rgba(0, 0, 0, 0.05) 50%,
    rgba(0, 0, 0, 0.09) 100%
  );
  border: 2px solid #d32ce6;
  border-radius: 22px;
}
.page-404-code .refer-input input {
  color: #ffc600;
  font-size: 36px;
  font-weight: 500;
  height: 62px;
  border-radius: 30px;
  padding: 0 20px;
}
.page-404 .link-copy:active {
  border-color: #8d1dd0;
  background-color: #8d1dd0;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.25) 0,
    transparent 100%
  );
}
.page-404-input-label {
  float: right;
  color: #ffc600;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.page-404:before {
  content: '';
  display: block;
  background: url(../images/bg-404.png) no-repeat;
  width: 1765px;
  height: 537px;
  position: absolute;
  top: 50px;
  left: -350px;
  z-index: -1;

  @media(max-width: 1240px) {
    width: 100%;
    height: 537px;
    position: absolute;
    top: 50px;
    left: -100px;
    z-index: -1;
    background-size: 100%;
  }
  @media(max-width: 1024px) {
    width: 1000px;
    left: 0;
  }
  @media(max-width: 560px) {
    left: -130px;
  }
  @media(max-width: 420px) {
    left: -170px;
  }
  @media(max-width: 359px) {
    left: -137px;
    width: 850px;
  }
}
.page-404 {
  width: 1200px;
  margin: 0 auto;
  min-height: 500px;
  position: relative;
  z-index: 2;

  @media(max-width: 1240px) {
    padding: 0 20px;
    width: 100%;
  }
  @media(max-width: 1024px) {
    padding-top: 160px;
  }
  @media(max-width: 359px) {
    padding-top: 120px;
  }
}
.page-404 .link-copy {
  background: 0 0;
  width: 32px;
  height: 32px;
  border: 2px solid #fff;
  border-radius: 50%;
  margin: 0;
  position: absolute;
  top: 15px;
  right: 15px;
}
.page-404 .link-copy:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: url(../images/icon-copy-white.svg) 50% 50% no-repeat;
}
.page-404 .link-copy:hover {
  background-color: #e4ae39;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.25) 0,
    rgba(255, 255, 255, 0.13) 50%,
    transparent 100%
  );
  border-color: #e4ae39;
}
