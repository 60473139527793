%before-after {
  content: "";
  display: block;
  position: absolute;
}
%icon {
  display: inline-block;
  vertical-align: text-bottom;
  position: relative;
}
%text-overflow {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
%clearfix {
  &:before,
  &:after {
    content: " ";
    display: block;
  }
  &:after {
    clear: both;
  }
}
%link-where {
  color: rgba(211, 44, 230, 0.5);
  font-size: 14px;
  line-height: 14px;
  display: flex;
  margin-left: auto;
  text-decoration: none;

  &:hover {
    color: #E69BFF;
  }
}