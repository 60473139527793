@import '../abstracts/extends';

.menu-landing {
  position: relative;
  width: 100%;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 158px;
    padding: 12px 20px 0 20px;
    margin: 0 -20px;

    @media (max-width: 1680px) {
      padding: 12px 0 0 0;
    }

    & > :last-child {
      a {
        &:before {
          display: none;
        }
      }
    }
  }

  li {
    &.active,
    &:hover {
      a {
        color: #d32ce6;

        &:after {
          background: radial-gradient(
              50% 100% at 50% 0%,
              #d32ce6 0%,
              rgba(29, 5, 56, 0.0001) 100%
            ),
            linear-gradient(
              180deg,
              #1e043a 0%,
              rgba(30, 4, 58, 0.64) 79%,
              rgba(29, 5, 56, 0.0001) 100%
            );
          mix-blend-mode: normal;
          opacity: 0.4;
          filter: blur(43.4925px);
        }
      }

      .icon {
        background: linear-gradient(300deg, #8214a8, #ff6ce3);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25))
          drop-shadow(0px 0px 78px #d32ce6);

        &:before {
          @extend %before-after;
          width: 8px;
          height: 8px;
          background: #d32ce6;
          border-radius: 50%;
          top: -23px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.325px;
    text-transform: uppercase;
    color: #cccccc;
    min-width: 128px;
    margin: 0 20px;
    position: relative;

    @media (max-width: 1680px) {
      min-width: 107px;
      margin: 0 10px;
    }

    &:after {
      @extend %before-after;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(117, 55, 0, 0.9) 0%,
        rgba(57, 15, 99, 0.9) 100%
      );
      mix-blend-mode: normal;
      width: 112px;
      height: 112px;
      z-index: -1;
      opacity: 0.4;
      margin-top: -13px;
      filter: blur(14px);
    }

    &:before {
      @extend %before-after;
      width: 1px;
      height: 90px;
      opacity: 0.2;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
      background: -moz-linear-gradient(
        top,
        rgba(176, 195, 217, 0) 0%,
        #b0c3d9 50%,
        rgba(176, 195, 217, 0) 100%
      );
      background: -webkit-linear-gradient(
        top,
        rgba(176, 195, 217, 0) 0%,
        #b0c3d9 50%,
        rgba(176, 195, 217, 0) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(176, 195, 217, 0) 0%,
        #b0c3d9 50%,
        rgba(176, 195, 217, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00b0c3d9", endColorstr="#00b0c3d9", GradientType=0);

      @media (max-width: 1680px) {
        right: -10px;
      }
    }
  }

  .icon {
    font-size: 36px;
    line-height: 36px;
    color: #ff9c0e;
    background: linear-gradient(300deg, #ff9c0e, #ffd800);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 15px;
  }

  .animate {
    .icon {
      animation: bounce 0.3s ease infinite alternate;
    }
  }
}

@keyframes bounce {
  100% {
    transform: translateY(-10px);
  }
}

@keyframes shake {
  from {
    transform: rotate(3deg);
  }
  to {
    transform-origin: center center;
    transform: rotate(-3deg);
  }
}
